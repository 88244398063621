import React from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useCookies } from "react-cookie";

import "../../Landing/asset/Landingcss/bootstrap.min.css";
import "../../Landing/asset/Landingcss/magnific-popup.css";
import "../../Landing/asset/Landingcss/nivo-lightbox.css";
import "../../Landing/asset/Landingcss/owl.carousel.min.css";
import "../../Landing/asset/Landingcss/owl.theme.css";
import "../../Landing/asset/Landingcss/responsive.css";

const OuterNav = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["userjwt"]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    // return (window.location.href = "/");
  };

  const runz = () => {
    window.localStorage.clear();
    removeCookie("userjwt");
    return (window.location.href = "/");
  };

  return (
    <header id="header-wrap">
      <nav className="navbar navbar-expand-md bg-inverse fixed-top scrolling-navbar">
        <div className="container">
          <a onClick={runz} href="/" className="navbar-brand">
            <h3
              style={{ color: "black", fontWeight: 700 }}
              className="text-xl font-semibold"
            >
              Test
              <span
                style={{
                  color: "black",
                  backgroundColor: "#f1c232",
                  fontSize: "27px",
                  fontWeight: 700,
                }}
              >
                RunZ
              </span>
            </h3>
          </a>
          {/* <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="lni-menu" />
          </button> */}
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav mr-auto w-100 justify-content-end clearfix">
              {/* <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li> */}

              {/* <li className="nav-item">
                <a className="nav-link" href="/#/documentation">
                  Docs
                </a>
              </li> */}
              <li className="nav-item">
                <a style={{fontSize:"22px"}} className="nav-link" href="/#/signup">
                  Sign up
                </a>
              </li>
              <li className="nav-item">
                <a
                style={{fontSize:"22px"}}
                  className="nav-link"
                  href = "/#/signin"
                >
                  Sign In
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="navhide"></div>
        <div className="navhide1">
          <IconButton
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
            // edge="end"
            color="inherit"
            // aria-label="menu"
            sx={{ mr: 2 }}
            id="basic-button"
            // aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            // aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              className="nav-item"
              onClick={() => {
                setAnchorEl(null);
                window.location.href = "/#/signup";
              }}
            >
              Sign up
            </MenuItem>
            {/* <MenuItem
              className="nav-item"
              onClick={() => {
                setAnchorEl(null);
                window.location.href = "/#/documentation";
              }}
            >
              Docs
            </MenuItem> */}
            <MenuItem
              className="nav-item"
              onClick={() => {
                setAnchorEl(null);
                window.location.href = "/#/signin";
              }}
            >
              Sign In
            </MenuItem>
          </Menu>
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default OuterNav;
