import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { GoogleAuthProvider } from "firebase/auth";

console.log(process.env);

const firebaseConfig = process.env.FIREBASE_CONFIG || '{"apiKey":"AIzaSyDNkpEarzBcmZ7Bgc3OlaMV09y6wHUPseM","authDomain":"testrunz-48ee2.firebaseapp.com", "projectId":"testrunz-48ee2","storageBucket":"testrunz-48ee2.appspot.com", "messagingSenderId": "950793974939", "appId":"1:950793974939:web:11c011450141a2db8f823e","measurementId": "G-ZKGYJXJZG1"}';

const firebaseApp = firebase.initializeApp(JSON.parse(firebaseConfig));

const db = firebaseApp.firestore();
const auth = firebase.auth(); 
const provider = new GoogleAuthProvider();

export {auth, provider};
export default db;
