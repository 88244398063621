import React, { useEffect, useState } from "react";
import ApiUrl from "../../../../../ServerApi";
import { useStateValue } from "../../../../../data/StateProvider";
import MaterialTable from "material-table";
import Lodaing from "../../Lodaing";
// import Modal from '@mui/material/Modal';
import Modal from "react-modal";
import Adduser from "../../Adduser/Adduser";
import ManageModal from "./ManageModal";

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 1000,
//   height:"70%",
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   overflowY:'scroll',
//   p: 4,
// };

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "50%",
    height: "70%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "2%",
  },
};

const customStylesbig = {
  content: {
    top: "50%",
    left: "50%",
    width: "100%",
    height: "100%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "2%",
  },
};

const Manageusertable = () => {
  const [{ user }] = useStateValue();

  const [userlist, setUserlist] = useState();
  const [loadingscreen, setLoadingscreen] = useState(true);
  const [update] = useState(true);
  const [modalOpenAdd, setModalOpenAdd] = useState(false);
  // const [lab, setLab] = useState();
  // const [options1, setOptions1] = useState();
  // const [options2, setOptions2] = useState();
  // const [lablist, setLablist] = useState();
  const [useridnum, setUseridnum] = useState();

  const columns = [
    { title: "ID", field: "_id", editable: "never" },
    { title: "Name", field: "name", editable: "never" },
    { title: "Email", field: "email", editable: "never" },
    {
      title: "Role",
      field: "role",
      lookup: {
        superadmin: "Super Admin",
        admin: "Admin",
        teacher: "Teacher",
        student: "Student",
      },
    },
    { title: "Country", field: "country", editable: "never" },
    { title: "Institute Name", field: "instituteName", editable: "never" },
  ];

  const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => {
  //   setOpen(false);
  //   setEditlabtype(true);
  //   setEditrole(true);
  //   setEditinstitutename(true);
  //   setEditcountry(true);
  //   setEditdepartment(true);
  //   setEditstate(true);
  //   setEdityear(true);
  //   setEditsemester(true);
  //   setEditlabtype(true);
  // };


  useEffect(() => {
    // console.log(user);
    fetch(`${ApiUrl}/api/getaccess`, {
      method: "POST",

      body: JSON.stringify({
        role: user.role,
        department: user.department,
        instituteName: user.instituteName,
        lab: user.labtype,
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setUserlist(json);
        // console.log(json);
      });
    // console.log(userlist);
    setLoadingscreen(false);
  }, [update, modalOpenAdd, open]);

  /// fetch lab

  // const fetchlab = () => {
  //   const fetchlbs = () => {
  //     fetch(`${ApiUrl}/moreInfo/labs/depandclg`, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         department: detail.department,
  //         institute: detail.instituteName,
  //       }),
  //       headers: {
  //         "Content-type": "application/json; charset=UTF-8",
  //       },
  //     })
  //       .then((response) => response.json())
  //       .then((json) => {
  //         // setOptions2(json.ids)
  //         console.log("json hekk  kkkp");
  //         setLablist(json.ids);
  //         console.log(json);
  //         console.log(typeof user.role);
  //       });
  //   };

  //   if (user.role == "superadmin") {
  //     fetchlbs();
  //   } else {
  //     console.log("list user");
  //     setLablist(user.labtype);
  //   }
  // };

  /// department fetch
  // const fetchdepartment = (institute) => {
  //   console.log(institute);
  //   fetch(`${ApiUrl}/moreInfo/department`, {
  //     method: "POST",

  //     body: JSON.stringify({
  //       institute: institute,
  //     }),
  //     headers: {
  //       "Content-type": "application/json; charset=UTF-8",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setOptions2(json.ids);

  //       console.log(json);
  //     });
  // };

  /// institute fetch
  // const fetchinstitute = () => {
  //   fetch(`${ApiUrl}/moreInfo/all/institute`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       setOptions1(data.ids);
  //     });
  // };
  /// filter lab
  // const filter = (labname) => {
  //   let templab = [...lab].filter((lab1) => lab1 !== labname);
  //   setLab(templab);
  // };

  // const patchsingle = (tag, value, id) => {
  //   fetch(`${ApiUrl}/api/tooglelab/admin`, {
  //     method: "PATCH",
  //     body: JSON.stringify({
  //       [tag]: value,
  //       id: id,
  //     }),
  //     headers: {
  //       "Content-type": "application/json; charset=UTF-8",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       alert("done");
  //       console.log(json);
  //       setUpdate(!update);
  //     });
  // };

  const edituser = async (userId) => {
    setOpen(true);
    setUseridnum(userId);
  };
  const edituseradmin = async (userId) => {
    // history.push(`/manageuseradmin/${userId}`);
    setOpen(true);
    setUseridnum(userId);
  };

  // content for add user modal
  const openModal = () => setModalOpenAdd(() => true);
  const closeModal = () => setModalOpenAdd(() => false);

  const closeeditModal = () => setOpen(() => false);

  return (
    <div>
      <Modal
        isOpen={open}
        appElement={document.getElementById("root")}
        style={customStylesbig}
        contentLabel="Manage User"
        disableBackdropClick="true"
        sx={{ overflow: "hidden" }}
      >
        <ManageModal useridnum={useridnum} closeeditModal={closeeditModal} />
      </Modal>
      {loadingscreen ? (
        <Lodaing />
      ) : user.role === "superadmin" ? (
        <>
          <Modal
            isOpen={modalOpenAdd}
            appElement={document.getElementById("root")}
            style={customStyles}
            contentLabel="add runz Modal"
            disableBackdropClick="true"
            sx={{ overflow: "hidden" }}
          >
            <Adduser closeModal={closeModal} />
          </Modal>

          <MaterialTable
            columns={columns}
            data={userlist}
            title="List Of Users"
            actions={[
              {
                icon: "add",
                tooltip: "Add User",
                isFreeAction: true,
                onClick: () => openModal(),
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              grouping: true,
              pageSizeOptions: [5, 10, 15],
              pageSize: 10,
              headerStyle: {
                zIndex: 0,
              },
            }}
            onRowClick={(e, data) => edituseradmin(data._id)}
          />
        </>
      ) : (
        <>
          <Modal
            isOpen={modalOpenAdd}
            appElement={document.getElementById("root")}
            style={customStyles}
            contentLabel="add runz Modal"
            disableBackdropClick="true"
            sx={{ overflow: "hidden" }}
          >
            <Adduser closeModal={closeModal} />
          </Modal>
          <MaterialTable
            columns={columns}
            data={userlist}
            title="List Of Users"
            actions={[
              {
                icon: "add",
                tooltip: "Add User",
                isFreeAction: true,
                onClick: () => openModal(),
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              grouping: true,
              pageSizeOptions: [5, 10, 15],
              pageSize: 10,
              headerStyle: {
                zIndex: 0,
              },
            }}
            onRowClick={(e, data) => edituser(data._id)}
          />
        </>
      )}
    </div>
  );
};

export default Manageusertable;
