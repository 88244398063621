import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import DeleteIcon from "@mui/icons-material/Delete";
import "./Graph.css";
import { useParams } from "react-router-dom";
import ApiService from "../../Sevices/ApiService";
import Swal from "sweetalert2";

export default function Multiaxis({ count, data, setData }) {
  const graphId = uuidv4();
  let { token } = useParams();
  count = parseInt(count) + 1;
  const [maxval1, setMaxval1] = useState(0);
  const [maxval2, setMaxval2] = useState(0);
  const [maxval3, setMaxval3] = useState(0);
  const [maxval4, setMaxval4] = useState(0);
  const [xAxis, setXAxis] = useState("x1");
  const [yAxis1, setYAxis1] = useState("y1");
  const [yAxis2, setYAxis2] = useState("y2");
  const [yAxis3, setYAxis3] = useState("y3");
  const [yAxis4, setYAxis4] = useState("y4");
  const [grapId, setGrapId] = useState(graphId);
  const [inputFields, setInputFields] = useState([
    {
      id: grapId,
      data: [
        {
          [xAxis]: "",
          [yAxis1]: "",
          [yAxis2]: "",
          [yAxis3]: "",
          [yAxis4]: "",
        },
      ],
    },
  ]);
  const [temp, setTemp] = useState([]);
  const [column, setColumn] = useState(1);

  useEffect(() => {
    let value = data;
    if (
      Array.isArray(value) &&
      value.length &&
      typeof value[0] !== "undefined"
    ) {
      setColumn(value[0].column);
      setInputFields(() => [
        {
          id: `${value[0].id}`,
          data: value[0].data,
        },
      ]);
      setGrapId(`${value[0].id}`);
      setXAxis(value[0].label.xaxis);
      setYAxis1(value[0].label.y1axis);
      setYAxis2(value[0].label.y2axis);
      setYAxis3(value[0].label.y3axis);
      setYAxis4(value[0].label.y4axis);
    }
  }, [count, data]);

  function generate() {
    let x = inputFields;
    x = x.find((data2) => data2.id === grapId).data;
    let yval1 = x.map((obj) => Number(obj[yAxis1]));
    let yval2 = x.map((obj) => Number(obj[yAxis2]));
    let yval3 = x.map((obj) => Number(obj[yAxis3]));
    let yval4 = x.map((obj) => Number(obj[yAxis4]));
    setMaxval1(() => Math.max(...yval1));
    setMaxval2(() => Math.max(...yval2));
    setMaxval3(() => Math.max(...yval3));
    setMaxval4(() => Math.max(...yval4));
    setTemp(x);
  }

  const handleChangeInput = (id, event) => {
    setInputFields((data1) => {
      let result = data1.find((data2) => data2.id === grapId).data;
      result[id][event.target.name] = event.target.value;
      if (column === 1 && event.target.name === yAxis1) {
        result[id + 1] = {
          [xAxis]: result[id + 1]?.[xAxis] ?? "",
          [yAxis1]: result[id + 1]?.[yAxis1] ?? "",
          [yAxis2]: result[id + 1]?.[yAxis2] ?? "",
          [yAxis3]: result[id + 1]?.[yAxis3] ?? "",
          [yAxis4]: result[id + 1]?.[yAxis4] ?? "",
        };
      } else if (column === 2 && event.target.name === yAxis2) {
        result[id + 1] = {
          [xAxis]: result[id + 1]?.[xAxis] ?? "",
          [yAxis1]: result[id + 1]?.[yAxis1] ?? "",
          [yAxis2]: result[id + 1]?.[yAxis2] ?? "",
          [yAxis3]: result[id + 1]?.[yAxis3] ?? "",
          [yAxis4]: result[id + 1]?.[yAxis4] ?? "",
        };
      } else if (column === 3 && event.target.name === yAxis3) {
        result[id + 1] = {
          [xAxis]: result[id + 1]?.[xAxis] ?? "",
          [yAxis1]: result[id + 1]?.[yAxis1] ?? "",
          [yAxis2]: result[id + 1]?.[yAxis2] ?? "",
          [yAxis3]: result[id + 1]?.[yAxis3] ?? "",
          [yAxis4]: result[id + 1]?.[yAxis4] ?? "",
        };
      } else if (column === 4 && event.target.name === yAxis4) {
        result[id + 1] = {
          [xAxis]: result[id + 1]?.[xAxis] ?? "",
          [yAxis1]: result[id + 1]?.[yAxis1] ?? "",
          [yAxis2]: result[id + 1]?.[yAxis2] ?? "",
          [yAxis3]: result[id + 1]?.[yAxis3] ?? "",
          [yAxis4]: result[id + 1]?.[yAxis4] ?? "",
        };
      }
      return [
        {
          id: grapId,
          data: result,
        },
      ];
    });
  };

  const handleRemoveFields = (id) => {
    setInputFields((data) => {
      const values = [...data].find((data2) => data2.id === grapId).data;
      if (id > -1) {
        values.splice(id, 1);
      }
      return [
        {
          id: grapId,
          data: values,
        },
      ];
    });
  };

  const check = () => {
    const data = {
      data: inputFields.find((data2) => data2.id === grapId).data,
      label: {
        xaxis: xAxis,
        y1axis: yAxis1,
        y2axis: yAxis2,
        y3axis: yAxis3,
        y4axis: yAxis4,
      },
      column: column,
      id: grapId,
    };
    let patchdata = {
      data: JSON.stringify(data),
      id: token,
      objectId: count - 1,
    };
    ApiService.patchplotdata(patchdata)
      .then(() => {
        Swal.fire("Data Saved", "Graph value has been saved", "success");
      })
      .catch((err) => {
        Swal.fire(
          "Error in Data Saved",
          "Graph value has not been saved",
          "error"
        );
      });
  };

  return (
    <div>
      <h5> Graph {count}:</h5>
      <label style={{ padding: "20px 20px 20px 0" }}>Column:</label>

      <select
        onChange={(e) => {
          setColumn(parseInt(e.target.value));
        }}
      >
        <option selected={column === 1} value={1}>
          1
        </option>
        <option selected={column === 2} value={2}>
          2
        </option>
        <option selected={column === 3} value={3}>
          3
        </option>
        <option selected={column === 4} value={4}>
          4
        </option>
      </select>

      <div className="row">
        <div className="column">
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    value={xAxis}
                    onChange={(e) => setXAxis(e.target.value)}
                  />
                </th>
                <th>
                  <input
                    value={yAxis1}
                    onChange={(e) => setYAxis1(e.target.value)}
                  />
                </th>
                {column >= 2 && (
                  <th>
                    <input
                      value={yAxis2}
                      onChange={(e) => setYAxis2(e.target.value)}
                    />
                  </th>
                )}
                {column >= 3 && (
                  <th>
                    <input
                      value={yAxis3}
                      onChange={(e) => setYAxis3(e.target.value)}
                    />
                  </th>
                )}
                {column >= 4 && (
                  <th>
                    <input
                      value={yAxis4}
                      onChange={(e) => setYAxis4(e.target.value)}
                    />
                  </th>
                )}
                <th>Remove row</th>
              </tr>
            </thead>
            <tbody>
              {inputFields
                .find((data) => data.id === grapId)
                .data.map((inputField, i) => (
                  <tr key={i}>
                    <td>
                      <input
                        name={xAxis}
                        style={{ width: "100px" }}
                        value={inputField[xAxis]}
                        onChange={(event) => handleChangeInput(i, event)}
                      />
                    </td>
                    <td>
                      <input
                        name={yAxis1}
                        style={{ width: "100px" }}
                        value={inputField[yAxis1]}
                        onChange={(event) => handleChangeInput(i, event)}
                      />
                    </td>
                    {column >= 2 && (
                      <td>
                        <input
                          name={yAxis2}
                          style={{ width: "100px" }}
                          value={inputField[yAxis2]}
                          onChange={(event) => handleChangeInput(i, event)}
                        />
                      </td>
                    )}
                    {column >= 3 && (
                      <td>
                        <input
                          name={yAxis3}
                          style={{ width: "100px" }}
                          value={inputField[yAxis3]}
                          onChange={(event) => handleChangeInput(i, event)}
                        />
                      </td>
                    )}
                    {column >= 4 && (
                      <td>
                        <input
                          name={yAxis4}
                          style={{ width: "100px" }}
                          value={inputField[yAxis4]}
                          onChange={(event) => handleChangeInput(i, event)}
                        />
                      </td>
                    )}
                    <td>
                      <Button
                        variant="contained"
                        color="error"
                        style={{ width: "100%", padding: "3px 3px 3px 5px" }}
                        disabled={inputFields.length < 1}
                        onClick={() => handleRemoveFields(i)}
                      >
                        Remove &nbsp;&nbsp;&nbsp;
                        <DeleteIcon />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <div className="column" style={{ margin: "100px" }}>
          {temp && (
            <ResponsiveContainer width="50%" aspect={2}>
              <LineChart
                width={500}
                height={300}
                data={temp}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={xAxis}>
                  <Label value={xAxis} offset={0} position="insideBottom" />
                </XAxis>

                <YAxis
                  yAxisId="right1"
                  orientation="left"
                  dataKey={yAxis1}
                  domain={[0, maxval1]}
                />
                {column >= 2 && (
                  <YAxis
                    yAxisId="right2"
                    orientation="left"
                    dataKey={yAxis2}
                    domain={[0, maxval2]}
                  />
                )}
                {column >= 3 && (
                  <YAxis
                    yAxisId="right3"
                    orientation="left"
                    dataKey={yAxis3}
                    domain={[0, maxval3]}
                  />
                )}
                {column >= 4 && (
                  <YAxis
                    yAxisId="right4"
                    orientation="left"
                    dataKey={yAxis4}
                    domain={[0, maxval4]}
                  />
                )}

                <Tooltip />
                <Legend />
                <Line
                  yAxisId="right1"
                  type="monotone"
                  dataKey={yAxis1}
                  stroke="#FF0000"
                  activeDot={{ r: 8 }}
                />
                {column >= 2 && (
                  <Line
                    yAxisId="right2"
                    type="monotone"
                    dataKey={yAxis2}
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                )}
                {column >= 3 && (
                  <Line
                    yAxisId="right3"
                    type="monotone"
                    dataKey={yAxis3}
                    stroke="#0000ff"
                    activeDot={{ r: 8 }}
                  />
                )}
                {column >= 4 && (
                  <Line
                    yAxisId="right4"
                    type="monotone"
                    dataKey={yAxis4}
                    stroke="#00FF00"
                    activeDot={{ r: 8 }}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>

      <Button
        variant="contained"
        onClick={() => generate()}
        style={{ background: "#F1C232", color: "black" }}
      >
        Generate
      </Button>
      <Button
        variant="outlined"
        style={{ marginLeft: "50px", color: "black", borderColor: "#F1C232" }}
        onClick={check}
      >
        Update
      </Button>

      <br />

      <br />
    </div>
  );
}
