import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@mui/material/Typography";
import "./Navbar.css";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";

const InnerNav = () => {
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["userjwt"]);
  

  const runz = () => {
    history.push("/app");
  };
  
  const logout = () => {
    window.localStorage.clear();
    removeCookie("userjwt");
    return (window.location.href = "/");
  };

  return (
    <div>
      <Toolbar>
        <Typography onClick={runz} sx={{ flexGrow: 1 }}>
          <span>Test</span>
          <span style={{ backgroundColor: "#F1C232" }}>RunZ</span>
        </Typography>
  
        <Typography
          onClick={logout}
          component="button"
          style={{
            backgroundColor: "#F1C232",
            marginRight: "70px",
            padding: "5px",
            borderRadius: "5px",
            borderWidth: "0.5px",
            borderColor: "gray",
          }}
        >
          Logout
        </Typography>

      </Toolbar>
    </div>
  );
};

export default InnerNav;
