import React from "react";
import Typography from "@mui/material/Typography";
import Toolbar from "@material-ui/core/Toolbar";
const PrivateNav = () => {
  return (
    <div>
      <Toolbar>
        <Typography>
          {" "}
          <span>Test</span>
          <span style={{ backgroundColor: "#F1C232" }}>RunZ</span>
        </Typography>
      </Toolbar>
    </div>
  );
};

export default PrivateNav;
