import React from 'react'

const Adminmsg = () => {
  return (
    <div>
        This page is Private You must be admin to View the content
    </div>
  )
}

export default Adminmsg