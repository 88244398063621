import React from "react";
import Manageusertable from "./Manageusertable";

const Manageuserpanel = () => {
  return (
    <div>
      <Manageusertable />
    </div>
  );
};

export default Manageuserpanel;
