import React, { useState } from "react";
import "./asset/Landingcss/landing.css";
import "./asset/Landingcss/bootstrap.min.css";
import "./asset/Landingcss/magnific-popup.css";
import "./asset/Landingcss/nivo-lightbox.css";
import "./asset/Landingcss/owl.carousel.min.css";
import "./asset/Landingcss/owl.theme.css";
import "./asset/Landingcss/responsive.css";
import "./asset/fonts/line-icons.css";
import "./asset/fonts/LineIcons.eot";
import "./asset/fonts/LineIcons.svg";
import "./asset/fonts/LineIcons.ttf";
import "./asset/fonts/LineIcons.woff";

import Main from "./asset/img/main.png";
import About from "./asset/img/about.png";
import SVCET from "./asset/img/institute/svcet.png";
import ACET from "./asset/img/institute/acet.png";
import Mailam from "./asset/img/institute/Mailam.png";
// import Feature from "./asset/img/Feature.png";
import Features from "./asset/img/Features.png";

import ApiUrl from "../ServerApi";

const Landing = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();

  const sendmessage = (event) => {
    event.preventDefault();
    if ((name, email, message)) {
      // send mail
      fetch(`${ApiUrl}/feedback/query`, {
        method: "POST",
        // signal:abortcont.signal,
        body: JSON.stringify({
          name: name,
          email: email,
          message: message,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          alert("Message sent!!!");
          setName("");
          setEmail("");
          setMessage("");
        });
    } else {
      alert("Enter all field");
    }
  };

  return (
    <div className="landing">
      {/* Hero Area Start */}
      <div id="hero-area" className="hero-area-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <div className="contents">
                <h4 className="head-title">Digital Experimentation</h4>
                <br/>
                <p className="para">
                  A web-based application that streamlines the experimentation workflow by digitizing test steps, processing the test data post-experimentation, and automatically generating reports.
                </p>
                <br/>
                <div className="header-button">
                  <button rel="nofollow" onClick={()=>window.location.href = "/#/signup"} className="getbtn">
                    Get Started
                  </button>
                  {/* <a href="https://www.youtube.com/watch?v=r44RKWyfcFw" className="btn btn-border video-popup">Demo Video</a> */}
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="intro-img">
                <img className="img-fluid" src={Main} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Hero Area End */}

      {/* Solutions Section Start */}
      <section id="solutions" className="section-padding solution">
        <div>
          <div className="section-header text-center">
            <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">
              Our Solutions
            </h2>
            <div className="shape wow fadeInDown" data-wow-delay="0.3s" />
          </div>
          <div className="row">
            {/* solutions item */}
            <div className="col-md-6 col-lg-4 col-xs-12">
              <div
                className="solutions-item wow fadeInRight"
                data-wow-delay="0.3s"
              >
                <div className="icon">
                  <i className="lni lni-search" />
                </div>
                <div className="solutions-content">
                  <h4 className="section-subtitle">Focus on experiments</h4>
                  <p className="para">
                  It frees up individuals to focus on the experiments and insights.
                  </p>
                </div>
              </div>
            </div>
            {/* solutions item */}
            <div className="col-md-6 col-lg-4 col-xs-12">
              <div
                className="solutions-item wow fadeInRight"
                data-wow-delay="0.6s"
              >
                <div className="icon">
                  <i className="lni-stats-up" />
                </div>
                <div className="solutions-content">
                  <h4 className="section-subtitle">Opportunity</h4>
                  <p className="para">
                  It enables individuals to gain practical experience and industry exposure.
                  </p>
                </div>
              </div>
            </div>
            {/* solutions item */}
            <div className="col-md-6 col-lg-4 col-xs-12">
              <div
                className="solutions-item wow fadeInRight"
                data-wow-delay="0.9s"
              >
                <div className="icon">
                  <i className="lni-users" />
                </div>
                <div className="solutions-content">
                  <h4 className="section-subtitle">Collaboration</h4>
                  <p className="para">
                  Provides the capability for individuals to collaborate in real-time.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* solutions Section End */}
      {/* About Section start */}
      <div className="about-area section-padding bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-xs-12 info">
              <div
                className="about-wrapper wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                <div>
                  <div className="site-heading">
                    <h2 className="section-title">
                      Detailed Statistics of your RunZ
                    </h2>
                  </div>
                  <div className="content">
                    <p className="para">
                    Users carry out their laboratory experiments using predefined test procedures and experiment libraries, which streamlines the experimentation and learning process. Additionally, Testrunz provides the capability for individuals to collaborate in real-time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-12 col-xs-12 wow fadeInRight"
              data-wow-delay="0.3s"
            >
              <img className="img-fluid" src={About} alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}
      {/* Features Section Start */}
      <section id="features" className="section-padding">
        <div>
          <div className="section-header text-center">
            <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">
              Awesome Features
            </h2>
            <div className="shape wow fadeInDown" data-wow-delay="0.3s" />
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div className="content-left">
                <div className="box-item wow fadeInLeft" data-wow-delay="0.3s">
                  <span className="icon">
                    <i className="lni-notepad" />
                  </span>
                  <div className="text">
                    <h4 className="section-subtitle">Procedures</h4>
                    <p className="para">
                    Over 1,000 experimentation procedures have been digitized.
                    </p>
                  </div>
                </div>
                <div className="box-item wow fadeInLeft" data-wow-delay="0.6s">
                  <span className="icon">
                    <i class="lni lni-alarm"></i>
                  </span>
                  <div className="text">
                    <h4 className="section-subtitle">Notification</h4>
                    <p className="para">
                    Once users submit their Runz, other users will be notified.
                    </p>
                  </div>
                </div>
                <div className="box-item wow fadeInLeft" data-wow-delay="0.9s">
                  <span className="icon">
                    <i className="lni-download" />
                  </span>
                  <div className="text">
                    <h4 className="section-subtitle">Runz report</h4>
                    <p className="para">
                    The experimentation workflow is streamlined through the digitization of test steps and automatic report generation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div className="show-box wow fadeInUp" data-wow-delay="0.3s">
                <img src={Features} alt="" />
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <div className="content-right">
                <div className="box-item wow fadeInRight" data-wow-delay="0.3s">
                  <span className="icon">
                    <i className="lni-magnifier" />
                  </span>
                  <div className="text">
                    <h4 className="section-subtitle">Monitoring</h4>
                    <p className="para">
                    Users can easily identify calculation mistakes.
                    </p>
                  </div>
                </div>
                <div className="box-item wow fadeInRight" data-wow-delay="0.6s">
                  <span className="icon">
                    <i className="lni-layers" />
                  </span>
                  <div className="text">
                    <h4 className="section-subtitle">
                      Teams &amp; Collaboration
                    </h4>
                    <p className="para">
                    There is no need to manually calculate the result as submitted Runz display the actual result.
                    </p>
                  </div>
                </div>
                <div className="box-item wow fadeInRight" data-wow-delay="0.9s">
                  <span className="icon">
                    <i className="lni-laptop-phone" />
                  </span>
                  <div className="text">
                    <h4 className="section-subtitle">Saves time</h4>
                    <p className="para">
                      No need to calculate the result. Submitted runz shown the
                      actual result.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Features Section End */}
      {/* institute Section Start */}
      <section id="institute" className="section-padding bg-gray">
      <div className="section-header text-center">
          <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">
            Participating Institutes
          </h2>
          <div className="shape wow fadeInDown" data-wow-delay="0.3s" />
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-9 col-md-9 col-sm-9 col-xs-9">
            <div
              id="institutes"
              className="wow row fadeInUp institutes"
              data-wow-delay="1.2s"
            >
              <div className="item col-md-4">
                <div className="institute-item">
                  <div className="img-thumb">
                    <img src={ACET} alt="" />
                  </div>
                </div>
              </div>
              <div className="item col-md-4">
                <div className="institute-item">
                  <div className="img-thumb">
                    <img src={Mailam} alt="" />
                  </div>
                </div>
              </div>
              <div className="item col-md-4">
                <div className="institute-item">
                  <div className="img-thumb">
                    <img src={SVCET} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* institute Section End */}
      {/* Contact Section Start */}
      <section id="contact" className="section-padding contact">
        {/* <div className="container"> */}
        <div className="section-header text-center">
          <h2 className="section-title wow fadeInDown" data-wow-delay="0.3s">
            Contact Us
          </h2>
          <div className="shape wow fadeInDown" data-wow-delay="0.3s" />
        </div>
        <div className="contact-form-area wow fadeInUp" data-wow-delay="0.3s">
          {/* Call To Action Section Start */}
          <section id="cta" className="section-padding">
            <div className="container">
              <div className="cta-text">
                <form id="contactForm" className="contactForm">
                  <div className="flex flex-wrap">
                    <div className="w-full sm:w-1/2 md:w-full lg:w-1/2">
                      <div className="mx-3">
                        <input
                          placeholder="Name"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          type="text"
                          className="form-input rounded-full"
                          id="name"
                          name="name"
                          required
                        />
                      </div>
                    </div>
                    <div className="w-full sm:w-1/2 md:w-full lg:w-1/2">
                      <div className="mx-3">
                        <input
                          placeholder="Email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          type="text"
                          id="email"
                          className="form-input rounded-full"
                          name="email"
                          required
                        />
                      </div>
                    </div>
                    <div className="w-full">
                      <div className="mx-3">
                        <textarea
                          placeholder="Type your message"
                          style={{ height: "100px" }}
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                          className="form-input rounded-lg"
                          id="message"
                          name="message"
                          data-error="Write your message"
                        />
                      </div>
                    </div>
                    <br/>
                    <div className="w-full">
                      <div className="mx-3">
                        <button
                          className="getbtn border-0 bg-yellow-600 rounded-full duration-300"
                          onClick={sendmessage}
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>
          {/* Call To Action Section Start */}
          {/* </div> */}
        </div>
      </section>
      {/* Contact Section End */}
      {/* Footer Section Start */}
      <footer id="footer" className="footer-area section-padding bg-gray">
        <div className="container">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-md-8 col-sm-6 col-xs-6 col-mb-18">
                <div className="widget">
                <h3
              style={{ color: "black", fontWeight: 700 }}
              className="text-xl font-semibold"
            >
              Test
              <span
                style={{
                  color: "black",
                  backgroundColor: "#f1c232",
                  fontSize: "27px",
                  fontWeight: 700,
                }}
              >
                RunZ
              </span>
            </h3>
                </div>
                <br />
                <div className="textwidget">
                  <h4 className="para" style={{fontWeight:"700"}}>About the company</h4>
                  <p className="para">
                  Testrunz helps prepare the scientists of tomorrow by enabling users to carry out their laboratory experiments using predefined test procedures and experiment libraries, thereby allowing them to gain practical experience and industry exposure.
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12" /> */}
              <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                {/* <h4 className="footer-titel">About us</h4>
                <ul className="footer-link">
                  <li>
                    <a href="#solutions">Solution</a>
                  </li>
                  <li>
                    <a href="#features">Features</a>
                  </li>
                  <li>
                    <a href="#clients">Clients</a>
                  </li>
                </ul> */}
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <h4 className="para" style={{fontWeight:"700"}}>For queries</h4>
                <div className="social-icon">
                  <a
                    className="envelope"
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=testrunz.learny@outlook.com&su=Queries about TestRunZ"
                  >
                    {/* https://mail.google.com/mail/?view=cm&fs=1&to=someone@example.com&su=SUBJECT&body=BODY */}
                    <i class="lni-envelope"></i>
                    </a>
                    <p className="para">testrunz.learny@outlook.com</p>
                  
                  {/* <a className="twitter" href="https://twitter.com/learny_tech">
                    <i className="lni-twitter-filled" />
                  </a>
                  <a className="instagram" href="https://www.instagram.com/learny_technologies/">
                    <i className="lni-instagram-filled" />
                  </a>
                  <a className="linkedin" href="https://www.linkedin.com/in/learny-technologies-641098181/">
                    <i className="lni-linkedin-filled" />
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="copyright-content">
                  <p style={{fontSize:"18px"}}>
                    Copyright 2023 © Learnytech | All rights reserved.
                    {/* <a
                      className="text-green-600"
                      rel="nofollow"
                      href="http://learnytech.com/"
                    >
                      Learnytech
                    </a> */}
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Section End */}
      {/* Go to Top Link */}
      <a href="/" className="back-to-top">
        <i className="lni-arrow-up" />
      </a>
      {/* Preloader
      <div id="preloader">
        <div className="loader" id="loader-1" />
      </div>
      End Preloader*/}
    </div>
  );
};

export default Landing;
