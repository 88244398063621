import React, { useEffect, useState } from "react";
import Multiaxis from "./Multiaxis";
import ApiUrl from "../../ServerApi";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useParams } from "react-router-dom";

const Graph = () => {
  const [axisCount, setAxisCount] = useState(0);
  const [data, setData] = useState([]);
  let { token } = useParams();

  useEffect(() => {
    fetch(`${ApiUrl}/experiments/${token}`)
      .then((res) => res.json())
      .then((data) => {
        setData([]);
        if (Array.isArray(data?.plotdata) && data?.plotdata.length) {
          setData(data.plotdata);
          setAxisCount(Number(data.plotdata.length) ?? 0);
        }
      })
      .catch((err) => {
        setData([]);
      });
  }, [token]);
  return (
    <div>
      <h4>GRAPH: </h4>
      <br />
      <label style={{ paddingTop: "15px" }}>No of Graph: </label>

      <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
        <Select
          value={axisCount}
          size="small"
          onChange={(e) => {
            setAxisCount(parseInt(e.target.value));
          }}
        >
          <MenuItem value={1}>One</MenuItem>
          <MenuItem value={2}>Two</MenuItem>
          <MenuItem value={3}>Three</MenuItem>
          <MenuItem value={4}>Four</MenuItem>
        </Select>
      </FormControl>

      {[...Array(axisCount)].map((e, i) => {
        return (
          <span key={i}>
            <Multiaxis count={i} data={[data[i]]} setData={setData} />
            <hr />
          </span>
        );
      })}
    </div>
  );
};

export default Graph;
